<template>
  <div>
    <div>
      <!-- Register v1 -->
      <b-card class="mb-0" title="">
        <!-- form -->
        <validation-observer ref="createpaymentForm" #default="{ invalid }">
          <b-form @submit.prevent>
            <!-- meeting link-->

            <b-col cols="12">
              <b-form-group label="User" label-for="user">
                <validation-provider
                  #default="{ errors }"
                  name="User"
                  vid="user"
                  rules="required"
                >
                  <v-select
                    id="status"
                    v-model="selected1"
                    :options="options1"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Paid Amount" label-for="meetingid">
                <validation-provider
                  #default="{ errors }"
                  name="Paid Amount"
                  vid="paid_amount"
                  rules="required|integer"
                >
                  <b-form-input
                    id="register-meetingid"
                    v-model="form.amount"
                    step="2"
                    name="register-paidmonthid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- current month number -->
            <b-col cols="12">
              <b-form-group label="Payment Date" label-for="paymentdate">
                <validation-provider
                  #default="{ errors }"
                  name="Payment Date"
                  vid="Payment Date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="form.payment_date"
                    name="register-payment_date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Button -->

            <b-button
              variant="primary"
              block
              type="submit"
              @click="createPayment()"
              :disabled="invalid"
            >
              Submit
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import paymentApi from "@/Api/Modules/payment";
import usersApi from "@/Api/Modules/auth";
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BFormDatepicker,
  BImg,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  name: "Register",
  components: {
    vSelect,
    BCard,
    BFormDatepicker,
    BCardTitle,
    BImg,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  async created() {
    await this.Users();
  },
  data() {
    return {
      form: {},
      selected1: {},
      options1: [],

      // validation rules
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
    };
  },

  methods: {
    async Users() {
      const res = await usersApi.allUsers();
      this.options1 = res.data.data;
      this.selected1 = this.options1[0];
    },
    async createPayment() {
      this.form.user_id = this.selected1.id;

      if (await this.$refs.createpaymentForm.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });
        await paymentApi
          .createPayment(this.form)
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
