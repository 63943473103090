import { api } from "../index";
import notification from "@/ApiConstance/toast";

export default {
  async createPayment(payload) {
    return await api.post("/storepayment", payload).then(() => {
      notification.toast("SuccessFully Created a Payment", "success");
      window.location.href = "/payments";
    });
  },
  async allPayments() {
    return await api.get("/getallpayments");
  },
};
